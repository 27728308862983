import React from "react";
import { ReactComponent as LogoWhiteSVG } from "./../../media/autonomu_logo.svg";

function Footer() {
  return (
    <section className=" pt-10 pb-4 px-5 md:px-10 bg-footer border-2 border-borderColor rounded-tl-big rounded-tr-big">
      <LogoWhiteSVG className="w-full md:w-6/12 lg:w-2/12" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-10 gap-x-32 mt-10">
        <div>
          <h1 className="text-white font-bold text-2xl 2xl:text-4xl">
            Get in Touch
          </h1>
          <p className="text-greyTone mt-2 lg:mt-4 text-md 2xl:text-xl ">
            Ready to bring your web automation ideas to life? Let's chat!
          </p>
          <div className="flex flex-col 2xl:flex-row gap-y-3 xl:gap-x-5 mt-5">
            <div className="rounded-full w-fit p-2 px-3 bg-mainColor">
              <a href="/#" className="text-primaryColor">
                hello@autonomu.com
              </a>
            </div>
          </div>
        </div>
        <div>
          <h1 className="text-white font-bold text-2xl 2xl:text-4xl ">
            Product Overview
          </h1>
          <p className="text-greyTone mt-2 lg:mt-4 text-md 2xl:text-xl ">
            Explore the Features and Capabilities of Our Web Automation Tool.
          </p>
          <div className="rounded-full p-2 px-3 bg-mainColor w-fit mt-5">
            <a href="/#" className="text-primaryColor">
              Contact us
            </a>
          </div>
        </div>
        <div>
          <h1 className="text-white font-bold text-2xl 2xl:text-4xl text-left xl:text-right">
            Follow for More
          </h1>
          <a
            href="/#"
            className="text-primaryColor mt-4 text-md 2xl:text-xl underline block text-left xl:text-right"
          >
            LinkedIn
          </a>
          <a
            href="/#"
            className="text-primaryColor mt-4 text-md 2xl:text-xl underline block text-left xl:text-right"
          >
            Twitter
          </a>
          <a
            href="/#"
            className="text-primaryColor text-md 2xl:text-xl underline block text-left xl:text-right mt-[10px]"
          >
            Facebook
          </a>
        </div>
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-y-5 pt-10 justify-between mt-14 border-t border-mainColor">
        <p className="text-primaryColor text-sm">
          © {new Date().getFullYear()} Autonomu. All Rights Reserved.
        </p>
        <a
          href="/#"
          className="underline text-primaryColor text-sm lg:text-center "
        >
          Privacy Policy
        </a>
        <a
          href="/#"
          className="underline text-primaryColor text-sm lg:text-right"
        >
          Terms & Conditions
        </a>
      </div>
    </section>
  );
}

export default Footer;
