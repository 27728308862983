import React from "react";
import Reveal from "../../Controls/Reveal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

function ContactForm() {
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(2, "First Name must be at least 2 characters")
      .max(50, "First Name must be 50 characters or less")
      .required("First Name is required"),
    lastName: Yup.string()
      .min(2, "Last Name must be at least 2 characters")
      .max(50, "Last Name must be 50 characters or less")
      .required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    webAddress: Yup.string()
      .url("Invalid URL")
      .required("Web Address is required"),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
  });

  return (
    <Reveal>
      <div className="w-full rounded-big py-5 px-2 md:p-8 bg-mainColor border-2 border-borderColor">
        <h2 className=" text-4xl text-[#f7f7f7] ">Request a Callback</h2>
        <p className="text-greyTone mt-3 mb-10">
          Please provide your details so we can connect you with the appropriate
          representative.
        </p>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            webAddress: "",
            phone: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="flex flex-col gap-y-4">
              <div className="flex flex-col lg:flex-row gap-4">
                <div className="text-left w-full">
                  <Field
                    placeholder="First Name"
                    className="mt-2 border-2 border-borderColor p-4 rounded-lg w-full text-white"
                    name="firstName"
                    type="text"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="error text-errorColor text-[14px] mt-3"
                  />
                </div>

                <div className="text-left w-full">
                  <Field
                    placeholder=" Last Name"
                    className="mt-2 border-2 border-borderColor p-4 rounded-lg w-full text-white"
                    name="lastName"
                    type="text"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="error text-errorColor text-[14px] mt-3"
                  />
                </div>
              </div>

              <div className="text-left">
                <Field
                  placeholder="Email"
                  className="mt-2 border-2 border-borderColor p-4 rounded-lg w-full text-white"
                  name="email"
                  type="email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error text-errorColor text-[14px] mt-3"
                />
              </div>

              <div className="text-left">
                <Field
                  placeholder="Web Address"
                  className="mt-2 border-2 border-borderColor p-4 rounded-lg w-full text-white"
                  name="webAddress"
                  type="url"
                />
                <ErrorMessage
                  name="webAddress"
                  component="div"
                  className="error text-errorColor text-[14px] mt-3"
                />
              </div>

              <div className="text-left">
                <Field
                  placeholder="Phone"
                  className="mt-2 border-2 border-borderColor p-4 rounded-lg w-full text-white"
                  name="phone"
                  type="text"
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="error text-errorColor text-[14px] mt-3"
                />
              </div>

              <button
                type="submit"
                className="mt-4 bg-primaryColor p-4 md:py-3 md:px-8 rounded-full hover:bg-accentColor ease-linear transition-all group  text-white text-2xl"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Reveal>
  );
}

export default ContactForm;
