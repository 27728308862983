import React from "react";
import Brand1SVG from "./../../../media/Tech_Google cloud-wht.png";
import Brand2SVG from "./../../../media/Tech_AWS-wht.png";
import Brand3SVG from "./../../../media/salesforce 2.png";
import Brand4SVG from "./../../../media/microsoft-dynamics-wht.png";
import Brand5SVG from "./../../../media/microsoft-wht.png";
import Reveal from "../../Controls/Reveal";

function KeyStats() {
  const brands = [
    { id: 1, svg: Brand1SVG },
    { id: 2, svg: Brand2SVG },
    { id: 3, svg: Brand3SVG },
    { id: 4, svg: Brand4SVG },
    { id: 5, svg: Brand5SVG },
  ];

  return (
    <section className="w-full py-12 md:pt-8 md:pb-24 md:px-0">
      <Reveal>
        <h4 className="text-white text-3xl text-center mx-auto block w-full">
          Transform the testing and delivery of{" "}
          <span className="font-bold text-primaryColor">
            Digital Experiences
          </span>
        </h4>
      </Reveal>

      <div className="grid gap-y-5 md:grid-cols-3 mt-10 pb-12 border-b border-solid border-borderColor items-center">
        <div className="border-b-2 py-5 md:border-b-0 md:border-r-2 px-10 border-dotted border-borderColor text-center">
          <Reveal>
            <h5 className="block font-bold text-5xl text-primaryColor text-center">
              87<span className="text-3xl">%</span>
            </h5>
          </Reveal>
          <Reveal>
            <p className="text-white ">
              Improvement in the speed of{" "}
              <span className="font-bold"> test creation</span>
            </p>
          </Reveal>
        </div>

        <div className="border-b-2 py-5 md:border-b-0 md:border-r-2 px-10 border-dotted border-borderColor text-center">
          <Reveal>
            <h1 className="block font-bold text-5xl text-primaryColor text-center">
              99<span className="text-3xl">x</span>
            </h1>
          </Reveal>
          <Reveal>
            <p className="text-white ">
              Expansion of
              <span className="font-bold"> test coverage</span>
            </p>
          </Reveal>
        </div>

        <div className="px-5 text-center">
          <Reveal>
            <h1 className="block font-bold text-5xl text-primaryColor text-center">
              5<span className="text-3xl">x</span>
            </h1>
          </Reveal>
          <Reveal>
            <p className="text-white ">
              Decrease in the need for
              <span className="font-bold"> test maintenance</span>
            </p>
          </Reveal>
        </div>
      </div>

      <div className="pt-12 text-center">
        <p className="text-greyTone ">As seen on</p>
        <div className="grid gap-10 grid-cols-2 md:grid-cols-3 lg:grid-cols-5 text-center">
          {brands.map((brand, index) => (
            <Reveal isStaggered={true} key={index} itemIndex={index}>
              <div className="w-[150px] h-[150px] flex justify-center items-center mx-auto">
                <img src={brand.svg} alt="brand" />
              </div>
            </Reveal>
          ))}
        </div>
      </div>
    </section>
  );
}

export default KeyStats;
