import React from "react";
import { ReactComponent as AutoCrossBrowserSVG } from "./../../../media/auto-crossbrowser.svg";
import { ReactComponent as AutoMobSVG } from "./../../../media/auto-mob.svg";
import { ReactComponent as AutoDirectSVG } from "./../../../media/auto-direct.svg";
import { ReactComponent as AutoMinCodeSVG } from "./../../../media/auto-lowcode.svg";
import { ReactComponent as AutoErrorHandleSVG } from "./../../../media/auto-errorhandle.svg";
import { ReactComponent as AutoAPIReliabilitySVG } from "./../../../media/auto-apireliability.svg";
import Reveal from "../../Controls/Reveal";
import CustomButton from "../../Controls/CustomButton";
import TiltCard from "../../Controls/TiltCard";

function Services() {
  const servicesData = [
    {
      title: "Autonomu Cross-Browser",
      description:
        "Guarantee optimal app appearance across all browser and operating system combinations.",
      icon: AutoCrossBrowserSVG,
    },
    {
      title: "Autonomu Mobile",
      description:
        "Provide flawless mobile app interactions across all devices & browsers.",
      icon: AutoMobSVG,
    },
    {
      title: "Autonomu Direct",
      description:
        "Revamp your test automation and CI/CD for quicker time-to-market.",
      icon: AutoDirectSVG,
    },
    {
      title: "Autonomu Minimal Code",
      description: "Simplify test case generation and implementation.",
      icon: AutoMinCodeSVG,
    },
    {
      title: "Autonomu Error-Handling",
      description:
        "Identify and resolve errors proactively, preventing user encounters.",
      icon: AutoErrorHandleSVG,
    },
    {
      title: "Autonomu API Reliability",
      description:
        "Guarantee top-notch API performance throughout the development process.",
      icon: AutoAPIReliabilitySVG,
    },
  ];

  return (
    <section className="rounded-big flex lg:my-20 flex-col items-start xl:flex-row gap-x-20 lg:p-5  ">
      <div className="w-full xl:w-5/12 relative self-stretch">
        <Reveal>
          <h1 className="text-white text-4xl md:text-5xl xl:text-6xl md:leading-tight w-full mx-auto mt-6">
            Utilize{" "}
            <span className="text-primaryColor italic font-bold">Autonomu</span>{" "}
            Anywhere: Test any application with AI
          </h1>
        </Reveal>

        <Reveal>
          <p className="text-greyTone mt-6 mx-auto">
            Create, test, and deploy top-tier web and mobile applications at
            enterprise levels. Simplify testing from development to post-launch
            phases. Access detailed analytics, identify errors swiftly, and
            resolve issues with agility.
          </p>
        </Reveal>

        <Reveal>
          <div className="mt-10">
            <CustomButton text={"Learn More"} />
          </div>
        </Reveal>
      </div>
      <div className="w-full mt-8 xl:w-7/12 grid grid-cols-1 lg:grid-cols-2 gap-6">
        {servicesData.map((service, index) => {
          return (
            <Reveal key={index} isStaggered itemIndex={index}>
              <TiltCard
                icon={service.icon}
                title={service.title}
                description={service.description}
              />
            </Reveal>
          );
        })}
      </div>
    </section>
  );
}

export default Services;
