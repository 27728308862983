import React from "react";
import Brand1SVG from "./../../../media/Tech_Google cloud-wht.png";
import Brand2SVG from "./../../../media/Tech_AWS-wht.png";
import Brand3SVG from "./../../../media/salesforce 2.png";
import Brand4SVG from "./../../../media/microsoft-dynamics-wht.png";
import Brand5SVG from "./../../../media/microsoft-wht.png";
import Reveal from "../../Controls/Reveal";

function Brands() {
  const brands = [
    { id: 1, svg: Brand1SVG },
    { id: 2, svg: Brand2SVG },
    { id: 3, svg: Brand3SVG },
    { id: 4, svg: Brand4SVG },
    { id: 5, svg: Brand5SVG },
  ];
  return (
    <section className="text-left lg:text-center">
      <div className="w-full lg:w-9/12 mx-auto">
        <Reveal>
          <h3 className="text-white text-2xl lg:text-5xl mb-5">
            <span className="text-primaryColor italic font-bold">Brands</span>{" "}
            we are working with.
          </h3>
        </Reveal>
        <Reveal>
          <p className="text-greyTone text-sm lg:text-lg">
            Our product has garnered recognition and trust from industry leaders
            worldwide. Its adoption by major companies across diverse sectors
            underscores its versatility and reliability. We're proud that our
            solution is contributing to the success of organizations at the
            forefront of innovation. Here's a glimpse of where our product has
            made an impact:
          </p>
        </Reveal>
      </div>

      <div className="mt-16 grid gap-10 grid-cols-2 md:grid-cols-3 lg:grid-cols-5 text-center">
        {brands.map((brand, index) => (
          <Reveal isStaggered={true} key={index} itemIndex={index}>
            <div className="w-[150px] h-[150px] flex justify-center items-center mx-auto">
              <img src={brand.svg} alt="brand" />
            </div>
          </Reveal>
        ))}
      </div>
    </section>
  );
}

export default Brands;
