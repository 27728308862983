import React from "react";
import { FaArrowRight } from "react-icons/fa6";

function CustomButton({ text }) {
  return (
    <button className="flex items-center gap-x-6 bg-primaryColor p-4 md:py-3 md:px-8 rounded-full hover:bg-accentColor ease-linear transition-all group">
      <p className="text-white text-nowrap text-sm md:text-xl lg:text-2xl flex font-bold group-hover:text-white">
        {text}
      </p>
      <FaArrowRight
        color="#111827"
        size={23}
        className="fill-white group-hover:translate-x-2 transition-transform duration-300"
      />
    </button>
  );
}

export default CustomButton;
