import React from "react";
import dashboardDummy from "./../../../media/Dashboard-01.png";
import smallGrids from "./../../../media/small-grids.png";
import Reveal from "../../Controls/Reveal";
import { ReactComponent as HomeSVG } from "./../../../media/icon_home.svg";
import { ReactComponent as SecuritySVG } from "./../../../media/icon_security.svg";
import { ReactComponent as SecureSVG } from "./../../../media/icon_secure.svg";
import { ReactComponent as CostSVG } from "./../../../media/icon_cost.svg";
import { ReactComponent as SettingsSVG } from "./../../../media/icon_setting.svg";

function Features() {
  const featuresData = [
    {
      icon: HomeSVG,
      title: "Dashboard",
      description: "Central hub for all your tools and data",
    },
    {
      icon: SecuritySVG,
      title: "Security Center",
      description: "Protect your data with robust security measures",
    },
    {
      icon: SecureSVG,
      title: "Privacy Lock",
      description: "Control access and permissions with ease",
    },
    {
      icon: CostSVG,
      title: "Financial Insights",
      description: "Track expenses and revenue at a glance",
    },
    {
      icon: SettingsSVG,
      title: "Settings",
      description: "Customize preferences for your workflow",
    },
  ];
  return (
    <section className="w-full md:px-0 py-20 rounded-lg ">
      <div className="w-full lg:w-9/12 xl:w-6/12 mx-auto text-center">
        <Reveal>
          <h3 className="text-white text-4xl md:text-5xl xl:text-6xl md:leading-tight pb-2">
            <span className="text-primaryColor font-bold italic">
              Versatile
            </span>{" "}
            Testing Solutions
          </h3>
        </Reveal>
        <Reveal>
          <p className="mt-6 text-greyTone">
            Conduct parallel, cross-browser web tests seamlessly on our cloud
            infrastructure or Selenium-compatible grids. Perform mobile tests on
            either physical or virtual devices, whether in the cloud, on
            third-party grids, or your local setup.
          </p>
        </Reveal>
      </div>
      <div>
        <Reveal>
          <div className="w-full lg:w-9/12 mx-auto">
            <div className="relative overflow-hidden flex justify-center">
              <img alt="small-grids" src={smallGrids} className="xl:mt-20" />
              <img
                alt="dashboard"
                src={dashboardDummy}
                className="mt-20 rounded-lg mx-auto absolute bottom-0 w-9/12"
              />
            </div>
          </div>
        </Reveal>

        <div className="bg-lightMainColor border border-borderColor py-10 px-6 mt-10 xl:mt-0 lg:px-6 lg:py-10 rounded-big ">
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-10 lg:grid-cols-3 gap-y-10 lg:gap-12 items-start justify-center mx-auto">
            {featuresData.map((feature, index) => {
              return (
                <Reveal key={index} isStaggered itemIndex={index}>
                  <div className="flex gap-3 items-center">
                    <feature.icon className="w-[20px] fill-accentColor" />
                    <p className="text-white text-xl font-bold">
                      {feature.title}
                    </p>
                  </div>

                  <p className="text-greyTone mt-2">{feature.description}</p>
                </Reveal>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
