import React from "react";
import logo from "./../../media/autonomu_octopus.svg";
import Navbar from "./components/Navbar";
import { Link } from "react-router-dom";

function Header() {
  return (
    <section className="flex justify-between items-center w-full">
      <Link id="RouterNavLink" to="/">
        <img src={logo} alt="site-logo" className="w-3/12 " />
      </Link>
      <Navbar />
      {/* <div className="flex gap-x-4 md:gap-x-8 w-full justify-end items-center">
        <button
          href="/#"
          className="text-white hover:text-primaryColor ease-linear transition-all"
        >
          Login
        </button>
        <button
          href="/#"
          className="text-white font-bold rounded-full bg-primaryColor p-2 md:p-3 hover:bg-accentColor hover:text-white ease-linear transition-all"
        >
          Try for Free
        </button>
      </div> */}
    </section>
  );
}

export default Header;
