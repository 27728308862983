import "./App.css";
import Home from "./components/Home/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./components/About/About";
import Product from "./components/Product/Product";

import Solutions from "./components/Solutions/Solutions";
import WebAutomation from "./components/Solutions/components/WebAutomation";
import AutomatedTesting from "./components/Solutions/components/AutomatedTesting";
import Contact from "./components/Contact/Contact";
import ProductDetail from "./components/Product/components/ProductDetail";
import DataIntegration from "./components/Solutions/components/DataIntegration";
import WorkflowOrchestration from "./components/Solutions/components/WorkflowOrchestration";
import ComplianceAutomation from "./components/Solutions/components/ComplianceAutomation";
import PipelineTesting from "./components/Solutions/components/PipelineTesting";

function App() {
  return (
    <div className="App relative pt-6 px-6 md:px-10 md:pt-5 lg:px-20 lg:pt-5 2xl:container 2xl:mx-auto 2xl:px-5 linear-bg">
      <BrowserRouter>
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/product" element={<Product />} />
          <Route
            path="/product/product-detail/:productlink"
            element={<ProductDetail />}
          />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/solutions/web-automation" element={<WebAutomation />} />
          <Route
            path="/solutions/pipeline-testing"
            element={<PipelineTesting />}
          />
          <Route
            path="/solutions/automated-testing"
            element={<AutomatedTesting />}
          />
          <Route
            path="/solutions/data-integration"
            element={<DataIntegration />}
          />
          <Route
            path="/solutions/workflow-orchestration"
            element={<WorkflowOrchestration />}
          />
          <Route
            path="/solutions/compliance-automation"
            element={<ComplianceAutomation />}
          />

          <Route path="/solutions" element={<Solutions />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
