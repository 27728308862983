import React from "react";
import useDocumentTitle from "../Controls/DynamicDocumentTitle";
import Reveal from "../Controls/Reveal";
import PageTag from "../Controls/PageTag";
import AboutAutonomu from "./components/AboutAutonomu";
import OurTeam from "./components/OurTeam";
import BorderSeparator from "../Controls/BorderSeparator";
import Brands from "./components/Brands";

function About() {
  useDocumentTitle("About | Autonomu");

  return (
    <section className="py-24 text-center">
      <div>
        <PageTag text={"About"} />
        <div className="mt-12 w-full lg:w-9/12 mx-auto">
          <Reveal>
            <h2 className="text-[#f1f1f1] text-2xl lg:text-6xl pb-2">
              Empower your digital journey with seamless automation, making
              every click count.
            </h2>
          </Reveal>
        </div>
        <div className="w-full lg:w-8/12 mt-4 mx-auto">
          <Reveal>
            <p className="text-md lg:text-lg text-greyTone">
              Our AI-driven platform for web automation seamlessly integrates
              your tasks, applications, and interactions, revolutionizing how
              you work. Join businesses worldwide in optimizing productivity and
              efficiency with our tool.
            </p>
          </Reveal>
        </div>
      </div>
      <BorderSeparator />
      <AboutAutonomu />
      <BorderSeparator />
      <OurTeam />
      <BorderSeparator />
      <Brands />
    </section>
  );
}

export default About;
