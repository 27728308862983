import React from "react";
import useDocumentTitle from "../../Controls/DynamicDocumentTitle";
import Reveal from "../../Controls/Reveal";
import CustomButton from "../../Controls/CustomButton";
import BorderSeparator from "../../Controls/BorderSeparator";
import { FaCircleCheck } from "react-icons/fa6";
import WebAutomationHero from "./../../../media/webAutomation-hero.png";
import WebAutomationForAutomator from "./../../../media/webAutomation-forautomator.png";
import WebAutomationForUser from "./../../../media/webAutomation-foruser.png";
import WebAutomationEcosystem from "./../../../media/webAutomation-ecosystem.png";

function WebAutomation() {
  useDocumentTitle("Web Automation | Autonomu");
  return (
    <section className="py-24">
      <div className="flex flex-col items-center gap-y-5 lg:flex-row">
        <div className="w-full lg:w-5/12 mx-auto flex flex-col gap-y-6">
          <Reveal>
            <h3 className="text-white text-5xl">
              Unleash the full potential of your web automation without the
              limitations.
            </h3>
          </Reveal>
          <Reveal>
            <p className="text-greyTone">
              Empowering. Intuitive. Entirely visual. Automate web tests
              effortlessly without needing to unravel complex code.
            </p>
          </Reveal>
          <Reveal>
            <CustomButton text={"Try Now"} />
          </Reveal>
        </div>
        <div className="w-full lg:w-5/12 mx-auto">
          <Reveal>
            <img
              src={WebAutomationHero}
              className="mx-auto"
              alt="webAutomation-hero"
            />
          </Reveal>
        </div>
      </div>
      <BorderSeparator />
      <div className="flex flex-col text-center gap-y-24">
        <div className="w-full lg:w-6/12 mx-auto">
          <Reveal>
            <h4 className="text-white text-xl lg:text-4xl">
              A robust platform seamlessly connecting tasks and solutions,
              facilitating enhanced and efficient web automation experiences.
            </h4>
          </Reveal>
        </div>
        <div className="flex flex-col gap-y-10 w-full lg:w-10/12 mx-auto">
          <div className="flex flex-col gap-y-5 lg:flex-row">
            <div className="w-full  lg:w-6/12">
              <Reveal>
                <img src={WebAutomationForAutomator} alt="webAutomation-hero" />
              </Reveal>
            </div>
            <div className="w-full lg:w-6/12 text-left">
              <Reveal>
                <h5 className="text-white text-4xl font-bold">For Automator</h5>
              </Reveal>
              <div className="flex flex-col gap-y-3 mt-5">
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Simplify task automation workflows
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Automate repetitive tasks with ease
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Generate detailed automation reports effortlessly
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Schedule and manage automated processes efficiently
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Seamlessly integrate with existing automation tools
                    </p>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-5 lg:flex-row-reverse">
            <div className="w-full lg:w-6/12">
              <Reveal>
                <img
                  src={WebAutomationForUser}
                  className="mx-auto"
                  alt="webAutomation-hero"
                />
              </Reveal>
            </div>
            <div className="w-full lg:w-6/12 text-left">
              <Reveal>
                <h5 className="text-white text-4xl font-bold">For User</h5>
              </Reveal>
              <div className="flex flex-col gap-y-3 mt-5">
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Access customized automation solutions automatically
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Receive real-time alerts for task completions and errors
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Interact with automated processes effortlessly
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Track progress and performance metrics conveniently
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Communicate with the automation system and other users
                      seamlessly
                    </p>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-20 rounded-big py-5 px-2 md:py-14 md:px-10 bg-lightMainColor border-2 border-borderColor text-center flex flex-col gap-y-5 lg:flex-row items-center">
        <div className="w-full lg:w-6/12">
          <div className="w-9/12">
            <Reveal>
              <img src={WebAutomationEcosystem} alt="webAutomation-hero" />
            </Reveal>
          </div>
        </div>
        <div className="w-full lg:w-5/12 text-left">
          <Reveal>
            <h4 className="text-white text-xl lg:text-4xl">
              Complete automation ecosystem, your one-stop solution for all
              tasks and processes
            </h4>
          </Reveal>

          <Reveal>
            <p className="text-greyTone mt-4 mb-10">
              Your ultimate automation destination, offering a centralized
              repository for all tasks. Simplify, streamline, and conquer with
              our comprehensive toolkit. Accessible, adaptable, and designed for
              seamless integration. Unlock efficiency and elevate productivity
              with our all-in-one solution.
            </p>
          </Reveal>
          <Reveal>
            <CustomButton text="Learn More" />
          </Reveal>
        </div>
      </div>
    </section>
  );
}

export default WebAutomation;
