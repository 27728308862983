import React from "react";
import useDocumentTitle from "../Controls/DynamicDocumentTitle";
import Reveal from "../Controls/Reveal";
import PageTag from "../Controls/PageTag";
import SolutionsGrid from "./components/SolutionsGrid";

function Solutions() {
  useDocumentTitle("Solutions | Autonomu");
  return (
    <section className="py-24 text-center">
      <div>
        <PageTag text={"Solutions"} />
        <div className="mt-12 w-full lg:w-10/12 mx-auto">
          <Reveal>
            <h2 className="text-[#f1f1f1] text-2xl lg:text-6xl pb-2">
              Optimize operations with Autonomu's adaptable solutions, tailored
              to your needs for peak efficiency.
            </h2>
          </Reveal>
        </div>
        <div className="w-full lg:w-9/12 mt-4 mx-auto">
          <Reveal>
            <p className="text-md lg:text-lg text-greyTone">
              Explore Autonomu's versatile solutions, designed to seamlessly
              integrate with diverse systems and products. From streamlining
              processes to enhancing efficiency, our tailored solutions empower
              you to automate tasks effortlessly, meeting the demands of modern
              automation needs.
            </p>
          </Reveal>
        </div>
      </div>

      <SolutionsGrid />
    </section>
  );
}

export default Solutions;
