import React, { useEffect } from "react";
import Hero from "./components/Hero";
import KeyStats from "./components/KeyStats";
import Process from "./components/Process";
import Features from "./components/Features";
import Services from "./components/Services";
import Testimonials from "./components/Testimonials";
import Lenis from "@studio-freight/lenis";
import useDocumentTitle from "../Controls/DynamicDocumentTitle";

function Home() {
  //Smooth Scrolling
  useEffect(() => {
    const lenis = new Lenis();

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
  }, []);

  useDocumentTitle("Autonomu");

  return (
    <main className="h-full">
      <Hero />
      <KeyStats />
      <Process />
      <Features />
      <Services />
      <Testimonials />
    </main>
  );
}

export default Home;
