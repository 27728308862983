import React from "react";
import { useParams } from "react-router-dom";
import productGrid from "../../../data/productData";
import { LuExternalLink } from "react-icons/lu";
import CustomButton from "../../Controls/CustomButton";
import BorderSeparator from "../../Controls/BorderSeparator";
import Reveal from "../../Controls/Reveal";
import useDocumentTitle from "../../Controls/DynamicDocumentTitle";

function ProductDetail() {
  const { productlink } = useParams();
  const selectedProduct = productGrid.find(
    (product) => product.link === productlink
  );

  useDocumentTitle(selectedProduct.title + " | Autonomu");

  return (
    <section className="py-24">
      <div className="flex flex-col lg:flex-row gap-x-10">
        <Reveal>
          <div className="border border-primaryColor w-[170px] h-[130px] bg-white rounded-lg flex justify-center items-center">
            <img
              src={selectedProduct.logo}
              alt={selectedProduct.title + " logo"}
              className="object-fit w-[70px]"
            />
          </div>
        </Reveal>

        <div className="w-full lg:w-8/12 mt-6 lg:mt-0">
          <Reveal>
            <h2 className="text-4xl text-white capitalize font-bold">
              {selectedProduct.title}
            </h2>
          </Reveal>

          <div className="mt-4 flex justify-between items-center w-fit gap-x-10">
            <Reveal>
              <p className=" text-greyTone uppercase tracking-widest font-semibold">
                Integration
              </p>
            </Reveal>
            <Reveal>
              <div className="flex gap-x-2 items-center cursor-pointer">
                <LuExternalLink color="white" />
                <p className="text-white underline">Visit Website</p>
              </div>
            </Reveal>
          </div>
          <Reveal>
            <p className="text-greyTone mt-4 mb-4">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt
              laudantium illum vitae explicabo asperiores repudiandae facilis
              nisi cumque dolore fugit beatae ratione quasi soluta, sapiente
              reprehenderit! Esse, quas. Aliquam, officia!
            </p>
          </Reveal>
          <Reveal>
            <div className="mt-8">
              <CustomButton text={"Try for Free"} />
            </div>
          </Reveal>
        </div>
      </div>
      <BorderSeparator />
      <div className="flex flex-col lg:flex-row gap-x-10">
        <div className="w-full xl:w-8/12 flex flex-col gap-y-10">
          <div>
            <Reveal>
              <h4 className="text-3xl text-white font-bold">
                About {selectedProduct.title}
              </h4>
            </Reveal>
            <Reveal>
              <p className="mt-3 text-greyTone">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maxime
                provident animi asperiores sunt exercitationem iusto eius
                commodi illum in eos impedit dolorem blanditiis, totam facilis
                doloremque necessitatibus odio optio eum! Earum repellat fugit
                ea itaque accusantium suscipit exercitationem eveniet cum atque
                aliquam unde error sint blanditiis adipisci ipsa, expedita,
                maxime, quas distinctio laboriosam et. Velit, vero labore
                aliquid magnam quos dolorem. Quis, magnam dignissimos. Pariatur,
                magnam odio, impedit aut distinctio, architecto quis hic dolore
                quasi laboriosam facilis in. Obcaecati placeat, blanditiis id
                totam exercitationem sunt temporibus, culpa sed minima optio et.
                Harum consectetur fuga laboriosam, atque a iste, similique unde
                error quis totam earum eveniet libero. Distinctio mollitia
                itaque maxime nemo. Voluptatibus quos, a corporis ipsa earum
                nostrum nemo. Repellat quae delectus cumque beatae itaque quia
                obcaecati repellendus, modi commodi consequuntur sapiente vitae,
                illo ex iste eveniet necessitatibus recusandae fugit fugiat
                numquam. Unde obcaecati illo consequatur ipsa nobis reiciendis
                earum.
              </p>
            </Reveal>
          </div>
          <div>
            <Reveal>
              <h4 className="text-3xl text-white font-bold">
                {selectedProduct.title} & Autonomu
              </h4>
            </Reveal>
            <Reveal>
              <p className="mt-3 text-greyTone">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maxime
                provident animi asperiores sunt exercitationem iusto eius
                commodi illum in eos impedit dolorem blanditiis, totam facilis
                doloremque necessitatibus odio optio eum! Earum repellat fugit
                ea itaque accusantium suscipit exercitationem eveniet cum atque
                aliquam unde error sint blanditiis adipisci ipsa, expedita,
                maxime, quas distinctio laboriosam et. Velit, vero labore
                aliquid magnam quos dolorem. Quis, magnam dignissimos. Pariatur,
                magnam odio, impedit aut distinctio, architecto quis hic dolore
                quasi laboriosam facilis in. Obcaecati placeat, blanditiis id
                totam exercitationem sunt temporibus, culpa sed minima optio et.
                Harum consectetur fuga laboriosam, atque a iste, similique unde
                error quis totam earum eveniet libero. Distinctio mollitia
                itaque maxime nemo. Voluptatibus quos, a corporis ipsa earum
                nostrum nemo. Repellat quae delectus cumque beatae itaque quia
                obcaecati repellendus, modi commodi consequuntur sapiente vitae,
                illo ex iste eveniet necessitatibus recusandae fugit fugiat
                numquam. Unde obcaecati illo consequatur ipsa nobis reiciendis
                earum.
              </p>
            </Reveal>
          </div>
          <div>
            <Reveal>
              <h4 className="text-3xl text-white font-bold">Use Cases</h4>
            </Reveal>
            <Reveal>
              <p className="mt-3 text-greyTone">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maxime
                provident animi asperiores sunt exercitationem iusto eius
                commodi illum in eos impedit dolorem blanditiis, totam facilis
                doloremque necessitatibus odio optio eum! Earum repellat fugit
                ea itaque accusantium suscipit exercitationem eveniet cum atque
                aliquam unde error sint blanditiis adipisci ipsa, expedita,
                maxime, quas distinctio laboriosam et. Velit, vero labore
                aliquid magnam quos dolorem. Quis, magnam dignissimos. Pariatur,
                magnam odio, impedit aut distinctio, architecto quis hic dolore
                quasi laboriosam facilis in. Obcaecati placeat, blanditiis id
                totam exercitationem sunt temporibus, culpa sed minima optio et.
                Harum consectetur fuga laboriosam, atque a iste, similique unde
                error quis totam earum eveniet libero. Distinctio mollitia
                itaque maxime nemo. Voluptatibus quos, a corporis ipsa earum
                nostrum nemo. Repellat quae delectus cumque beatae itaque quia
                obcaecati repellendus, modi commodi consequuntur sapiente vitae,
                illo ex iste eveniet necessitatibus recusandae fugit fugiat
                numquam. Unde obcaecati illo consequatur ipsa nobis reiciendis
                earum.
              </p>
            </Reveal>
          </div>
        </div>
        <div className="w-full xl:w-4/12 mt-6 lg:mt-0 sticky top-[20px] flex flex-col gap-y-10 border border-borderColor p-6 rounded-lg h-fit">
          <div>
            <h5 className="text-2xl text-white">Tags</h5>
            <div className="mt-3 flex flex-wrap gap-y-3 xl:flex-row gap-x-3 [&>*]:bg-primaryColor [&>*]:rounded-full [&>*]:w-fit [&>*]:py-1 [&>*]:px-3 [&>*]:text-sm lg:[&>*]:text-lg [&>*]:text-nowrap">
              <p>Automation</p>
              <p>Testing</p>
              <p>Web Automation</p>
            </div>
          </div>
          <div>
            <h5 className="text-2xl text-white">Resources</h5>
            <div className="flex flex-col gap-y-3 mt-3">
              <div className="flex gap-x-2 items-center cursor-pointer">
                <LuExternalLink color="white" />
                <p className="text-white underline">JumpIntoTheUnknown.com</p>
              </div>
              <div className="flex gap-x-2 items-center cursor-pointer">
                <LuExternalLink color="white" />
                <p className="text-white underline">WanderlustWonders.com</p>
              </div>
              <div className="flex gap-x-2 items-center cursor-pointer">
                <LuExternalLink color="white" />
                <p className="text-white underline">PixelPioneer.net</p>
              </div>
              <div className="flex gap-x-2 items-center cursor-pointer">
                <LuExternalLink color="white" />
                <p className="text-white underline">MysticMeadows.org</p>
              </div>
              <div className="flex gap-x-2 items-center cursor-pointer">
                <LuExternalLink color="white" />
                <p className="text-white underline">QuantumQuests.com</p>
              </div>
            </div>
          </div>
          <div>
            <h5 className="text-2xl text-white">Integrations</h5>
            <div className="flex flex-col gap-y-1 mt-3">
              <p className="text-greyTone">
                Engineered by: <span className="font-bold">Autonomu</span>
              </p>
              <p className="text-greyTone">
                Need Help? Contact Us at: support@autonomu.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductDetail;
