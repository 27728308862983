import { useEffect } from "react";

const useDocumentTitle = (title) => {
  useEffect(() => {
    document.title = title;
    window.scrollTo(0, 0);
  }, [title]);
};

export default useDocumentTitle;
