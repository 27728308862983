import React from "react";
import Reveal from "../Controls/Reveal";
import useDocumentTitle from "../Controls/DynamicDocumentTitle";
import ContactForm from "./components/ContactForm";
import CustomButton from "./../../components/Controls/CustomButton";
import { FaArrowRight } from "react-icons/fa6";
import PageTag from "../Controls/PageTag";

function Contact() {
  useDocumentTitle("Contact | Autonomu");
  return (
    <section className="py-24 text-center">
      <PageTag text={"Contact Us"} />
      <div className="mt-20 w-full flex flex-col md:flex-row items-center gap-10 justify-between">
        <div className="text-left w-full lg:w-5/12 flex flex-col gap-y-10">
          <div>
            <Reveal>
              <h2 className="text-[#f1f1f1] text-2xl lg:text-6xl pb-2">
                We're Here to Help
              </h2>
            </Reveal>
            <Reveal>
              <p className="text-md lg:text-lg text-greyTone">
                For any questions or assistance, feel free to reach out to our
                team. We're committed to providing you with the support you
                need.
              </p>
            </Reveal>
            <div className="mt-8 flex flex-col gap-8">
              <Reveal>
                <div className="flex gap-3 items-center mt-6">
                  <FaArrowRight color="#fff" size={20} />
                  <h5 className="font-semiBold text-xl lg:text-3xl text-greyTone">
                    Choose the Perfect Plan
                  </h5>
                </div>
              </Reveal>
              <Reveal>
                <div className="flex gap-3 items-center">
                  <FaArrowRight color="#fff" size={20} />
                  <h5 className="font-semiBold text-xl lg:text-3xl text-greyTone">
                    Try an Autonomu Demo
                  </h5>
                </div>
              </Reveal>
              <Reveal>
                <div className="flex gap-3">
                  <FaArrowRight color="#fff" size={20} />
                  <h5 className="font-semiBold -mt-[5px] text-xl lg:text-3xl text-greyTone">
                    Discover How Autonomu Can Benefit Your Business
                  </h5>
                </div>
              </Reveal>
            </div>
            <Reveal>
              <h5 className="italic text-greyTone mt-8">
                *Our sales team is available for calls in English, Spanish, and
                Portuguese.
              </h5>
            </Reveal>
          </div>
          <Reveal>
            <CustomButton text={"Call Us"} />
          </Reveal>
        </div>
        <ContactForm />
      </div>
    </section>
  );
}

export default Contact;
