import React from "react";
import useDocumentTitle from "../../Controls/DynamicDocumentTitle";
import CustomButton from "../../Controls/CustomButton";
import BorderSeparator from "../../Controls/BorderSeparator";
import { FaCircleCheck } from "react-icons/fa6";
import Reveal from "../../Controls/Reveal";

function ComplianceAutomation() {
  useDocumentTitle("Compliance Automation | Autonomu");
  return (
    <section className="py-24">
      <div className="flex flex-col gap-y-5 lg:flex-row">
        <div className="w-full lg:w-8/12 mx-auto flex flex-col gap-y-6 text-center">
          <Reveal>
            <h3 className="text-white text-5xl">
              Streamline your compliance processes with our automation
              solutions.
            </h3>
          </Reveal>
          <Reveal>
            <p className="text-greyTone">
              Ensure adherence to regulations effortlessly. Automate compliance
              workflows to minimize risk and maintain continuous compliance.
            </p>
          </Reveal>
          <div className="mx-auto">
            <Reveal>
              <CustomButton text={"Get Started"} />
            </Reveal>
          </div>
        </div>
      </div>
      <BorderSeparator />
      <div className="flex flex-col text-center gap-y-24">
        <div className="flex flex-col gap-y-10">
          <div className="flex flex-col gap-y-5 lg:flex-row-reverse">
            <div className="w-full lg:w-6/12">
              <h2 className="text-2xl text-white">Image</h2>
            </div>
            <div className="w-full lg:w-6/12 text-left">
              <Reveal>
                <h5 className="text-3xl text-white font-semibold">
                  Transform your compliance management with automation.
                </h5>
              </Reveal>
              <Reveal>
                <p className="text-greyTone mt-4">
                  Automate compliance tasks, reduce manual errors, and ensure
                  your organization stays compliant with the latest regulations.
                </p>
              </Reveal>

              <div className="flex flex-col gap-y-3 mt-5">
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Automate policy enforcement and monitoring
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Ensure continuous compliance with regulatory updates
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Generate compliance reports effortlessly
                    </p>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-20 rounded-big py-5 px-2 md:py-14 md:px-10 bg-lightMainColor border-2 border-borderColor text-center flex flex-col gap-y-5 lg:flex-row">
        <div className="w-full lg:w-9/12 mx-auto flex flex-col gap-y-6 text-center">
          <Reveal>
            <h3 className="text-white text-5xl pb-1">
              Advanced Compliance Strategies
            </h3>
          </Reveal>
          <Reveal>
            <p className="text-greyTone">
              Enhance compliance management with cutting-edge automation
              techniques. Enable seamless regulatory adherence and minimize
              compliance risks.
            </p>
          </Reveal>
          <div className="mt-10 grid justify-between grid-row md:grid-cols-2 xl:grid-cols-3 gap-y-8 md:gap-x-8 ">
            <Reveal>
              <div>
                <div className="w-full 2xl:w-9/12 mx-auto text-white">Img</div>
                <h6 className="text-lg text-white break-keep whitespace-nowrap font-bold">
                  Automated Auditing
                </h6>
                <p className="text-greyTone mt-2">
                  Conduct audits automatically to ensure compliance standards
                  are met.
                </p>
              </div>
            </Reveal>
            <Reveal>
              <div>
                <div className="w-full 2xl:w-9/12 mx-auto text-white">Img</div>
                <h6 className="text-lg text-white break-keep whitespace-nowrap font-bold">
                  Risk Management
                </h6>
                <p className="text-greyTone mt-2">
                  Identify and mitigate compliance risks with proactive
                  automation.
                </p>
              </div>
            </Reveal>
            <Reveal>
              <div>
                <div className="w-full 2xl:w-9/12 mx-auto text-white">Img</div>
                <h6 className="text-lg text-white break-keep whitespace-nowrap font-bold">
                  Regulatory Compliance
                </h6>
                <p className="text-greyTone mt-2">
                  Stay ahead of regulatory changes with automated compliance
                  updates.
                </p>
              </div>
            </Reveal>
            <Reveal>
              <div>
                <div className="w-full 2xl:w-9/12 mx-auto text-white">Img</div>
                <h6 className="text-lg text-white break-keep whitespace-nowrap font-bold">
                  Data Privacy
                </h6>
                <p className="text-greyTone mt-2">
                  Ensure data privacy compliance with automated controls.
                </p>
              </div>
            </Reveal>
            <Reveal>
              <div>
                <div className="w-full 2xl:w-9/12 mx-auto text-white">Img</div>
                <h6 className="text-lg text-white break-keep whitespace-nowrap font-bold">
                  Incident Management
                </h6>
                <p className="text-greyTone mt-2">
                  Automate incident response to swiftly address compliance
                  issues.
                </p>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
      <div className="pt-24 flex flex-col text-center gap-y-24">
        <div className="flex flex-col gap-y-10">
          <div className="flex flex-col gap-y-5 lg:flex-row-reverse">
            <div className="w-full lg:w-6/12 text-left">
              <Reveal>
                <h5 className="text-3xl text-white font-semibold">
                  Achieve continuous compliance and mitigate risks with advanced
                  automation.
                </h5>
              </Reveal>
              <Reveal>
                <p className="text-greyTone mt-4">
                  Leverage automation to ensure your organization adheres to
                  compliance standards. Stay ahead of regulations and avoid
                  costly compliance breaches.
                </p>
              </Reveal>

              <div className="flex flex-col gap-y-3 mt-5">
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Streamline compliance workflows and processes
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Ensure adherence to industry standards and regulations
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Automate reporting and documentation processes
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Enhance data integrity and security with automated checks
                    </p>
                  </div>
                </Reveal>
              </div>
            </div>
            <div className="w-full lg:w-6/12">
              <h2 className="text-2xl text-white">Image</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ComplianceAutomation;
