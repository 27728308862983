import React, { useState } from "react";
import Reveal from "../../Controls/Reveal";
import solutionsGrid from "./../../../data/solutionsData";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";

function SolutionsGrid() {
  const [activeIndex, setActiveIndex] = useState("");
  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex("");
  };
  return (
    <section className="lg:py-16">
      <div className="mt-10 grid grid-row md:grid-cols-2 xl:grid-cols-3 gap-y-6 md:gap-x-6 ">
        {solutionsGrid.map((solution, index) => {
          return (
            <Reveal key={index} isStaggered itemIndex={index}>
              <Link to={solution.link}>
                <div
                  className={`rounded-big cursor-pointer p-8 md:p-10 text-left transition-all bg-mainColor ${
                    activeIndex === index
                      ? "border-5 border-primaryColor ease-linear transition-all"
                      : ""
                  } border border-borderColor`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="w-full 2xl:w-9/12 mx-auto">
                    <div className="bg-primaryColor/10 h-[200px] w-[200px] rounded-full absolute left-15"></div>
                    <Lottie
                      animationData={solution.image}
                      loop={true}
                      className="h-[220px]"
                    />
                  </div>

                  <div className="w-full items-center flex gap-x-5 mt-10">
                    <h1 className="text-2xl text-white break-keep whitespace-nowrap  font-bold">
                      {solution.title}
                    </h1>
                    <div className="bg-primaryColor h-1 w-full"></div>
                  </div>
                  <p className="text-greyTone mt-5">{solution.description}</p>
                </div>
              </Link>
            </Reveal>
          );
        })}
      </div>
    </section>
  );
}

export default SolutionsGrid;
