import React from "react";
import useDocumentTitle from "../../Controls/DynamicDocumentTitle";
import CustomButton from "../../Controls/CustomButton";
import BorderSeparator from "../../Controls/BorderSeparator";
import { FaCircleCheck } from "react-icons/fa6";
import Reveal from "../../Controls/Reveal";
import PipelineTesting1 from "./../../../media/pipelineTesting-1.png";
import PipelineTesting2 from "./../../../media/pipelineTesting-2.png";
import ModularDesign from "./../../../media/modulerdesign.png";
import CICDIntegration from "./../../../media/integration.png";
import ParallelExecution from "./../../../media/parallelexecution.png";
import RiskbasedPrior from "./../../../media/riskbased.png";
import FeedbackDriven from "./../../../media/feedbackdriven.png";

function PipelineTesting() {
  useDocumentTitle("Pipeline Testing | Autonomu");
  return (
    <section className="py-24">
      <div className="flex flex-col gap-y-5 lg:flex-row">
        <div className="w-full lg:w-8/12 mx-auto flex flex-col gap-y-6 text-center">
          <Reveal>
            <h3 className="text-white text-5xl">
              Maximize your Pipeline Testing efficiency with unbounded
              automation capabilities.
            </h3>
          </Reveal>
          <Reveal>
            <p className="text-greyTone">
              Enabling. Intuitive. Visually-driven. Simplify Pipeline Testing
              with Seamless Automation, Free from Complicated Scripting.
            </p>
          </Reveal>
          <div className="mx-auto">
            <Reveal>
              <CustomButton text={"Try Now"} />
            </Reveal>
          </div>
        </div>
      </div>
      <BorderSeparator />
      <div className="flex flex-col text-center gap-y-24">
        <div className="flex flex-col gap-y-10">
          <div className="flex flex-col items-center gap-y-20 lg:flex-row-reverse">
            <div className="w-full lg:w-6/12">
              <Reveal>
                <img
                  src={PipelineTesting1}
                  alt="pipelineTesting-1"
                  className="mx-auto"
                />
              </Reveal>
            </div>
            <div className="w-full lg:w-6/12 text-left">
              <Reveal>
                <h5 className="text-3xl text-white font-semibold">
                  Transform pipeline testing into a seamless journey with
                  automation's unlimited potential.
                </h5>
              </Reveal>
              <Reveal>
                <p className="text-greyTone mt-4">
                  Experience a paradigm shift in pipeline testing as automation
                  transcends boundaries, unleashing unparalleled efficiency.
                  Every interaction fuels innovation, pushing the limits of
                  what's possible with each effortless click. Embrace a new era
                  where streamlined workflows and limitless potential converge,
                  revolutionizing how we test and iterate.
                </p>
              </Reveal>

              <div className="flex flex-col gap-y-3 mt-5">
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Interact with automated processes effortlessly
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Track progress and performance metrics conveniently
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Communicate with the automation system and other users
                      seamlessly
                    </p>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-20 rounded-big py-5 px-2 md:py-14 md:px-10 bg-lightMainColor border-2 border-borderColor text-center flex flex-col gap-y-5 lg:flex-row">
        <div className="w-full lg:w-9/12 mx-auto flex flex-col gap-y-6 text-center">
          <Reveal>
            <h3 className="text-white text-5xl pb-1">
              Streamlined Pipeline Strategies
            </h3>
          </Reveal>
          <Reveal>
            <p className="text-greyTone">
              Streamline processes, accelerate delivery, and enhance quality
              assurance with every automated test. Empower teams to focus on
              driving core business goals by leveraging seamless automation
              tailored to your organization's unique objectives. Experience the
              synergy of technology and strategy, propelling your business
              forward with precision and purpose.
            </p>
          </Reveal>
          <div className="mt-10 grid justify-between grid-row md:grid-cols-2 xl:grid-cols-3 gap-y-8 md:gap-x-8 ">
            <Reveal>
              <div>
                <div className=" mx-auto text-white bg-mainColor border border-borderColor rounded-lg p-1 mb-4 w-[80px] h-[80px]">
                  <img src={ModularDesign} alt="ModularDesign" />
                </div>
                <h6 className="text-lg text-white break-keep whitespace-nowrap  font-bold">
                  Modular Test Design
                </h6>
                <p className="text-greyTone mt-2">
                  Split tests for scalability and maintainability in evolving
                  pipelines.
                </p>
              </div>
            </Reveal>
            <Reveal>
              <div>
                <div className=" mx-auto text-white bg-mainColor border border-borderColor rounded-lg p-1 mb-4 w-[80px] h-[80px]">
                  <img src={CICDIntegration} alt="CICDIntegration" />
                </div>
                <h6 className="text-lg text-white break-keep whitespace-nowrap  font-bold">
                  (CI/CD) Integration
                </h6>
                <p className="text-greyTone mt-2">
                  Automate tests across development stages for seamless quality
                  assurance.
                </p>
              </div>
            </Reveal>
            <Reveal>
              <div>
                <div className=" mx-auto text-white bg-mainColor border border-borderColor rounded-lg p-1 mb-4 w-[80px] h-[80px]">
                  <img src={ParallelExecution} alt="ParallelExecution" />
                </div>
                <h6 className="text-lg text-white break-keep whitespace-nowrap  font-bold">
                  Parallel Execution
                </h6>
                <p className="text-greyTone mt-2">
                  Optimize efficiency by running tests across multiple
                  environments.
                </p>
              </div>
            </Reveal>
            <Reveal>
              <div>
                <div className=" mx-auto text-white bg-mainColor border border-borderColor rounded-lg p-1 mb-4 w-[80px] h-[80px]">
                  <img src={RiskbasedPrior} alt="RiskbasedPrior" />
                </div>
                <h6 className="text-lg text-white break-keep whitespace-nowrap  font-bold">
                  Risk-based Prioritization
                </h6>
                <p className="text-greyTone mt-2">
                  Allocate resources wisely by focusing testing efforts on
                  critical areas.
                </p>
              </div>
            </Reveal>
            <Reveal>
              <div>
                <div className=" mx-auto text-white bg-mainColor border border-borderColor rounded-lg p-1 mb-4 w-[80px] h-[80px]">
                  <img src={FeedbackDriven} alt="FeedbackDriven" />
                </div>
                <h6 className="text-lg text-white break-keep whitespace-nowrap  font-bold">
                  Feedback-Driven Optimization
                </h6>
                <p className="text-greyTone mt-2">
                  Continuously refine strategy based on insightful test results
                  analysis.
                </p>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
      <div className="pt-24 flex flex-col text-center gap-y-24">
        <div className="flex flex-col gap-y-10">
          <div className="flex flex-col items-center gap-y-20 lg:flex-row-reverse">
            <div className="w-full lg:w-6/12 text-left">
              <Reveal>
                <h5 className="text-3xl text-white font-semibold">
                  Unlock the full potential of data analysis with cutting-edge
                  automation technology.
                </h5>
              </Reveal>
              <Reveal>
                <p className="text-greyTone mt-4">
                  Embark on a transformative journey as automation transcends
                  traditional boundaries, redefining efficiency. Every insight
                  drives innovation, expanding possibilities with every
                  analysis. Embrace a new era where streamlined workflows and
                  limitless potential converge, revolutionizing data-driven
                  decision-making.
                </p>
              </Reveal>

              <div className="flex flex-col gap-y-3 mt-5">
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Analyze complex datasets effortlessly
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Visualize insights and trends conveniently
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Collaborate with team members seamlessly
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Track progress and performance metrics conveniently
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Interact with automated processes effortlessly
                    </p>
                  </div>
                </Reveal>
              </div>
            </div>
            <div className="w-full lg:w-6/12">
              <Reveal>
                <img
                  src={PipelineTesting2}
                  alt="pipelineTesting-2"
                  className="mx-auto"
                />
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PipelineTesting;
