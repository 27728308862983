import React from "react";
import Reveal from "../Controls/Reveal";
import PageTag from "../Controls/PageTag";
import useDocumentTitle from "../Controls/DynamicDocumentTitle";
import ProductGrid from "./components/ProductGrid";

function Product() {
  useDocumentTitle("Product | Autonomu");
  return (
    <section className="py-24 text-center">
      <div>
        <PageTag text={"Product"} />
        <div className="mt-12 w-full lg:w-10/12 mx-auto">
          <Reveal>
            <h2 className="text-[#f1f1f1] text-2xl lg:text-6xl pb-2">
              Experience Autonomu's dynamic capabilities, integrating with
              diverse products for effortless automation.
            </h2>
          </Reveal>
        </div>
        <div className="w-full lg:w-9/12 mt-4 mx-auto">
          <Reveal>
            <p className="text-md lg:text-lg text-greyTone">
              Autonomu offers a dynamic array of capabilities, seamlessly
              integrating with a diverse range of products. This integration
              empowers users to effortlessly automate tasks across multiple
              platforms and systems. Whether it's streamlining processes or
              enhancing efficiency, Autonomu provides a versatile solution
              tailored to meet the demands of modern automation needs.
            </p>
          </Reveal>
        </div>
      </div>
      <ProductGrid />
    </section>
  );
}

export default Product;
