import React from "react";
import Reveal from "../../Controls/Reveal";
import teamImage1 from "./../../../media/team1.jpg";
import teamImage2 from "./../../../media/team2.jpg";
import teamImage3 from "./../../../media/team3.jpg";
import teamImage4 from "./../../../media/team4.jpg";
import teamImage5 from "./../../../media/team5.jpg";

function OurTeam() {
  const teamMembers = [
    {
      name: "John Doe",
      role: "Developer",
      imageUrl: teamImage1,
    },
    {
      name: "Michael Johnson",
      role: "Project Manager",

      imageUrl: teamImage2,
    },
    {
      name: "Jane Smith",
      role: "Designer",
      imageUrl: teamImage3,
    },
    {
      name: "Emily Brown",
      role: "Marketing Specialist",
      imageUrl: teamImage4,
    },
    {
      name: "David Williams",
      role: "QA Engineer",
      imageUrl: teamImage5,
    },
  ];

  return (
    <section className="text-left lg:text-center">
      <div className="w-full lg:w-9/12 mx-auto">
        <Reveal>
          <h3 className="text-white text-2xl lg:text-5xl mb-5">
            Meet our{" "}
            <span className="text-primaryColor italic font-bold">Team</span>{" "}
          </h3>
        </Reveal>
        <Reveal>
          <p className="text-greyTone text-sm lg:text-lg">
            Our team consists of dedicated professionals who are passionate
            about delivering excellence in every aspect of our work. With
            diverse backgrounds and expertise, we collaborate seamlessly to
            bring innovative solutions to our clients. Together, we strive to
            exceed expectations and drive success for our partners. Get to know
            the talented individuals behind our product:
          </p>
        </Reveal>
      </div>

      <div className="mt-8 lg:mt-20 grid grid-cols-2 lg:grid-cols-4 gap-y-10">
        {teamMembers.map((member, index) => (
          <Reveal isStaggered={true} key={index} itemIndex={index}>
            <div className="w-[100px] lg:w-[200px] h-[100px] lg:h-[200px] mx-auto rounded-full overflow-hidden">
              <img
                className="object-cover w-full h-full"
                src={member.imageUrl}
                alt={member.name + " image"}
              />
            </div>
            <div className="mt-6 text-center">
              <h5 className="text-white text-xl font-semiBold">
                {member.name}
              </h5>
              <p className="text-greyTone text-sm">{member.role}</p>
            </div>
          </Reveal>
        ))}
      </div>
    </section>
  );
}

export default OurTeam;
