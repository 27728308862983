import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
// import required modules
import { EffectCards } from "swiper/modules";
import TestimonialArrow from "./../../../media/testimonial-arrow.png";
import TestimonialPic1 from "./../../../media/test-1.jpg";
import TestimonialPic2 from "./../../../media/test-2.jpg";
import TestimonialPic3 from "./../../../media/test-3.jpg";
import TestimonialPic4 from "./../../../media/test-4.jpg";
import TestimonialPic5 from "./../../../media/test-5.jpg";
import TestimonialPic6 from "./../../../media/test-6.jpg";
import Reveal from "../../Controls/Reveal";

function Testimonials() {
  const testimonials = [
    {
      name: "Linda Jack",
      position: "Operations Manager, Swift Logistics",
      testimonial:
        "Our operations have never been smoother since implementing Autonomu. Tasks that used to take hours now happen with a click. Game-changer!",
      picture: TestimonialPic4,
    },
    {
      name: "Mark Johnson",
      position: "IT Director, TechSavvy Inc.",
      testimonial:
        "A must-have for any IT team! Autonomu has saved us countless hours of manual work. Our systems are more efficient than ever.",
      picture: TestimonialPic1,
    },
    {
      name: "Jessica Wong",
      position: "Marketing Coordinator, DigitalWave Agency",
      testimonial:
        "No more tedious data entry! Thanks to Autonomu, we've increased our marketing output without the extra effort. Love it!",
      picture: TestimonialPic5,
    },
    {
      name: "Daniel Lee",
      position: "E-commerce Manager, Trendy Finds Co.",
      testimonial:
        "Our online store runs seamlessly with Autonomu. Inventory updates, order processing—everything is now automatic. Huge time-saver!",
      picture: TestimonialPic2,
    },
    {
      name: "Catherine Evans",
      position: "HR Specialist",
      testimonial:
        "Streamlining HR processes was a breeze with Autonomu. From onboarding to payroll, it handles it all. Couldn't be happier!",
      picture: TestimonialPic6,
    },
    {
      name: "James Rodriguez",
      position: "Finance Manager, Summit Enterprises",
      testimonial:
        "Effortless financial reporting with Autonomu. Complex calculations and reports generated in minutes. Wish we had this sooner!",
      picture: TestimonialPic3,
    },
  ];

  return (
    <section className="pb-24 py-12 md:py-24 flex flex-col md:flex-row justify-between items-center gap-y-10 md:gap-y-0">
      <div className="w-full md:w-4/12">
        <Reveal>
          <h1 className="text-white text-4xl md:text-5xl xl:text-6xl md:leading-tight">
            Hear what our
            <span className="text-primaryColor font-bold italic">
              {" "}
              Clients{" "}
            </span>
            say
          </h1>
        </Reveal>

        <Reveal>
          <p className="mt-6 text-greyTone">
            Discover firsthand experiences from our valued clients. Dive into
            their stories and testimonials to learn how our services have made a
            difference in their lives. Explore the impact of our work through
            the voices of those we serve.
          </p>
        </Reveal>
      </div>
      <div className="w-full md:w-8/12 flex xl:ml-20 items-start md:items-center justify-center gap-x-3 md:gap-x-10">
        <div className="hidden xl:block">
          <img
            src={TestimonialArrow}
            alt="testimonial-arrow"
            className="w-6/12 md:w-full scale-x-[-1]"
          />
          <p className="text-primaryColor mt-3 md:mt-1 rotate-[40deg] m-[-20px]">
            swipe!
          </p>
        </div>
        <Swiper
          effect={"cards"}
          grabCursor={true}
          modules={[EffectCards]}
          className="mySwiper w-[160px] h-[260px] md:w-[280px] md:h-[380px] 2xl:w-[400px] 2xl:h-[500px] rotate-[5deg]"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide
              key={index}
              className="p-3 md:p-5 flex items-center justify-center rounded-[18px] bg-lightMainColor border-2 border-borderColor"
            >
              <img
                src={testimonial.picture}
                alt={testimonial.name + " image"}
                className="rounded-full w-[60px] h-[60px] md:w-[100px] md:h-[100px] 2xl:w-[200px] 2xl:h-[200px] mx-auto"
              />
              <h5 className="text-primaryColor text-lg md:text-1xl xl:text-2xl 2xl:text-3xl font-bold mt-2 text-center">
                {testimonial.name}
              </h5>
              <p className="text-greyTone text-[10px] md:text-[12px] 2xl:text-[14px] text-center leading-tight">
                {testimonial.position}
              </p>
              <p className="text-[10px] text-white md:text-[15px] 2xl:text-lg mt-2 md:mt-5">
                {testimonial.testimonial}
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}

export default Testimonials;
