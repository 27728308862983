import React from "react";
import { motion } from "framer-motion";
import CustomButton from "../../Controls/CustomButton";
import heroImage1 from "./../../../media/Sign-in.png";
import heroImage2 from "./../../../media/record-card.png";
import heroImage3 from "./../../../media/unlock-session.png";
import Reveal from "./../../Controls/Reveal";
import PageTag from "./../../Controls/PageTag";

function Hero() {
  const letterAnim = {
    hidden: {
      y: -300,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const titleText = ["A", "U", "T", "O", "N", "O", "M", "U"];

  return (
    <section className="text-center w-full py-20">
      <PageTag text={"Introducing"} />

      <Reveal>
        <h1 className="custom-hero-title text-5xl md:text-8xl xl:text-[146px] text-linear-bg tracking-widest font-bold inline-flex">
          {titleText.map((text, index) => {
            return (
              <span key={index} variants={letterAnim} className="text-none">
                {text}
              </span>
            );
          })}
        </h1>
      </Reveal>
      <Reveal>
        <h4 className="text-white mt-1 font-light text-xl md:text-2xl xl:text-3xl md:leading-tight w-full lg:w-8/12 mx-auto">
          Business automation platform transforming
          <span className="text-primaryColor mx-2 italic font-bold">
            insights
          </span>
          into
          <span className="text-primaryColor mx-2 italic font-bold">
            innovation
          </span>
          at your desired pace
          <span className="animate-ping-custom text-primaryColor">.</span>
        </h4>
      </Reveal>

      <div className="flex justify-center items-center mt-14 relative">
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.5, type: "spring", duration: 2 }}
          className="perspective-1 mr-[-40px] lg:mr-[-70px]"
        >
          <img
            src={heroImage1}
            alt="hero-left"
            className="rounded-big w-fit object-cover border-2 border-primaryColor z-10 custom-box-shadow"
          />
        </motion.div>

        <motion.img
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, type: "spring", duration: 2 }}
          src={heroImage2}
          alt="hero-center"
          className="rounded-big border-2 border-primaryColor z-20 border-box custom-box-shadow md:w-5/12 lg:w-[unset]"
        />

        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.5, type: "spring", duration: 2 }}
          className="perspective-2 ml-[-40px] lg:ml-[-70px]"
        >
          <img
            src={heroImage3}
            alt="hero-right"
            className="rounded-big border-2 border-primaryColor z-10 custom-box-shadow"
          />
        </motion.div>
      </div>

      <div className="pl-6 border border-primaryColor rounded-full w-fit mx-auto mt-20 flex gap-1 custom-box-shadow">
        <input
          type="text"
          placeholder="Enter your email..."
          className="w-full xl:w-[250px] focus:outline-none text-white"
        />
        <CustomButton text={"Get Started"} />
      </div>
    </section>
  );
}

export default Hero;
