import React, { useState } from "react";
import ProcessIcon from "./../../../media/process-icon.png";
import Lottie from "lottie-react";
import TestingAnimation from "./../../../media/Testing.json";
import ApplicationAnimation from "./../../../media/Application.json";
import AIAnimation from "./../../../media/Ai.json";
import Reveal from "../../Controls/Reveal";

function Process() {
  const contentData = [
    {
      id: 1,
      svg: TestingAnimation,
      title: "Testing",
      content:
        "Streamline your testing process by selecting the mode that best suits your expertise and project requirements. With options ranging from no-code for quick setups to low-code or full-code for detailed customization.",
    },
    {
      id: 2,
      svg: ApplicationAnimation,
      title: "Application",
      content:
        "Say goodbye to the hassle of managing multiple testing platforms. This versatile solution allows you to test any application type—whether it's a web, mobile, API, or desktop application—all within a single interface.",
    },
    {
      id: 3,
      svg: AIAnimation,
      title: "AI",
      content:
        "Take advantage of cutting-edge AI capabilities to supercharge your testing efforts. From AI-generated tests that provide accurate and efficient results to collaborative co-authoring features that boost team productivity.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState("");
  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex("");
  };

  return (
    <section className="rounded-big py-5 px-2 md:p-5 bg-lightMainColor border-2 border-borderColor text-center">
      <Reveal>
        <img src={ProcessIcon} alt="process-icon" className="mx-auto" />
      </Reveal>

      <Reveal>
        <h1 className="text-white text-4xl md:text-5xl xl:text-6xl pb-2 md:leading-tight w-full md:w-9/12 mx-auto mt-6">
          Accelerate your pace with a{" "}
          <span className="text-primaryColor italic font-bold">modern</span>{" "}
          method of testing
        </h1>
      </Reveal>

      <Reveal>
        <p className="text-greyTone mt-6 w-full md:w-10/12 mx-auto">
          Streamline your testing process with a versatile solution that offers
          no-code, low-code, or full-code options. Create automated tests
          effortlessly using record-and-playback or drag-and-drop features,
          while also customizing with script mode for tailored actions. This
          all-in-one tool allows you to test any application type—web, mobile,
          API, or desktop—eliminating the need for multiple platforms. Whether
          on SaaS, on-premise, or hybrid cloud models, stay compliant with
          industry standards. Harness the power of AI at the core of your
          testing, enabling AI-generated tests, co-authoring, actionable
          insights, and maintenance-free testing. Simplify, streamline, and
          scale your testing with this comprehensive solution.
        </p>
      </Reveal>

      <div className="mt-10 grid grid-row md:grid-cols-2 xl:grid-cols-3 gap-y-6 xl:gap-y-0 md:gap-x-6 ">
        {contentData.map((content, index) => {
          return (
            <Reveal key={index} isStaggered itemIndex={index}>
              <div
                className={`rounded-big p-8 md:p-10 text-left transition-all bg-mainColor ${
                  activeIndex === index
                    ? "border-5 border-primaryColor ease-linear transition-all"
                    : ""
                } border border-borderColor`}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="w-full 2xl:w-9/12 mx-auto">
                  <div className="bg-primaryColor/10 h-[200px] w-[200px] rounded-full absolute left-15"></div>
                  <Lottie
                    animationData={content.svg}
                    loop={true}
                    className="2xl:h-[220px]"
                  />
                </div>

                <div className="w-full items-center flex gap-x-5 mt-10">
                  <h1 className="text-2xl text-white break-keep whitespace-nowrap  font-bold">
                    {content.title}
                  </h1>
                  <div className="bg-primaryColor h-1 w-full"></div>
                </div>
                <p className="text-greyTone mt-5">{content.content}</p>
              </div>
            </Reveal>
          );
        })}
      </div>
    </section>
  );
}

export default Process;
