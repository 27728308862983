import React from "react";
import Reveal from "./Reveal";

function PageTag({ text }) {
  return (
    <Reveal>
      <div className="mx-auto w-full">
        <p className="text-accentColor text-md px-5 py-1 w-8/12 md:w-fit mx-auto border border-accentColor rounded-full intro-text mb-5">
          {text}
        </p>
      </div>
    </Reveal>
  );
}

export default PageTag;
