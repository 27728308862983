import React from "react";
import useDocumentTitle from "../../Controls/DynamicDocumentTitle";
import CustomButton from "../../Controls/CustomButton";
import BorderSeparator from "../../Controls/BorderSeparator";
import { FaCircleCheck } from "react-icons/fa6";
import Reveal from "../../Controls/Reveal";

function DataIntegration() {
  useDocumentTitle("Data Integration | Autonomu");
  return (
    <section className="py-24">
      <div className="flex flex-col gap-y-5 lg:flex-row">
        <div className="w-full lg:w-8/12 mx-auto flex flex-col gap-y-6 text-center">
          <Reveal>
            <h3 className="text-white text-5xl">
              Seamlessly integrate your data with advanced automation
              capabilities.
            </h3>
          </Reveal>
          <Reveal>
            <p className="text-greyTone">
              Empower your organization with intuitive data integration
              solutions. Simplify the process of connecting disparate data
              sources and unlocking valuable insights effortlessly.
            </p>
          </Reveal>
          <div className="mx-auto">
            <Reveal>
              <CustomButton text={"Get Started"} />
            </Reveal>
          </div>
        </div>
      </div>
      <BorderSeparator />
      <div className="flex flex-col text-center gap-y-24">
        <div className="flex flex-col gap-y-10">
          <div className="flex flex-col gap-y-5 lg:flex-row-reverse">
            <div className="w-full lg:w-6/12">
              <h2 className="text-2xl text-white">Image</h2>
            </div>
            <div className="w-full lg:w-6/12 text-left">
              <Reveal>
                <h5 className="text-3xl text-white font-semibold">
                  Transform your data integration process into a streamlined
                  journey with automation's boundless potential.
                </h5>
              </Reveal>
              <Reveal>
                <p className="text-greyTone mt-4">
                  Experience a paradigm shift in data integration as automation
                  transcends traditional methods, enabling seamless connectivity
                  and insights discovery. Each interaction drives innovation,
                  propelling your organization towards data-driven excellence.
                </p>
              </Reveal>

              <div className="flex flex-col gap-y-3 mt-5">
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Connect disparate data sources effortlessly
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Automate data transformation and cleansing processes
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Visualize integrated data for actionable insights
                    </p>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-20 rounded-big py-5 px-2 md:py-14 md:px-10 bg-lightMainColor border-2 border-borderColor text-center flex flex-col gap-y-5 lg:flex-row">
        <div className="w-full lg:w-9/12 mx-auto flex flex-col gap-y-6 text-center">
          <Reveal>
            <h3 className="text-white text-5xl pb-1">
              Advanced Data Integration Strategies
            </h3>
          </Reveal>
          <Reveal>
            <p className="text-greyTone">
              Enhance data accessibility, reliability, and agility with
              innovative data integration approaches. Enable seamless
              collaboration across departments and derive actionable insights
              from your integrated data ecosystem.
            </p>
          </Reveal>
          <div className="mt-10 grid justify-between grid-row md:grid-cols-2 xl:grid-cols-3 gap-y-8 md:gap-x-8 ">
            <Reveal>
              <div>
                <div className="w-full 2xl:w-9/12 mx-auto text-white">Img</div>
                <h6 className="text-lg text-white break-keep whitespace-nowrap  font-bold">
                  Real-time Data Integration
                </h6>
                <p className="text-greyTone mt-2">
                  Seamlessly integrate real-time data streams for
                  up-to-the-minute insights.
                </p>
              </div>
            </Reveal>
            <Reveal>
              <div>
                <div className="w-full 2xl:w-9/12 mx-auto text-white">Img</div>
                <h6 className="text-lg text-white break-keep whitespace-nowrap  font-bold">
                  Cloud Data Migration
                </h6>
                <p className="text-greyTone mt-2">
                  Migrate and integrate data across cloud platforms securely and
                  efficiently.
                </p>
              </div>
            </Reveal>
            <Reveal>
              <div>
                <div className="w-full 2xl:w-9/12 mx-auto text-white">Img</div>
                <h6 className="text-lg text-white break-keep whitespace-nowrap  font-bold">
                  Master Data Management
                </h6>
                <p className="text-greyTone mt-2">
                  Centralize and manage critical business data for improved
                  decision-making.
                </p>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
      <div className="pt-24 flex flex-col text-center gap-y-24">
        <div className="flex flex-col gap-y-10">
          <div className="flex flex-col gap-y-5 lg:flex-row-reverse">
            <div className="w-full lg:w-6/12 text-left">
              <Reveal>
                <h5 className="text-3xl text-white font-semibold">
                  Harness the power of integrated data for actionable insights
                  and informed decision-making.
                </h5>
              </Reveal>
              <Reveal>
                <p className="text-greyTone mt-4">
                  Embrace a data-driven culture with seamless data integration.
                  Uncover hidden patterns, trends, and opportunities that drive
                  business growth and innovation.
                </p>
              </Reveal>

              <div className="flex flex-col gap-y-3 mt-5">
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Integrate data from multiple sources effortlessly
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Ensure data consistency and accuracy across the
                      organization
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Enable self-service data access for faster decision-making
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Implement data-driven strategies with confidence and
                      agility
                    </p>
                  </div>
                </Reveal>
              </div>
            </div>
            <div className="w-full lg:w-6/12">
              <h2 className="text-2xl text-white">Image</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DataIntegration;
