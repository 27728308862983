import React from "react";
import Reveal from "../../Controls/Reveal";
import { Link } from "react-router-dom";
import productGrid from "./../../../data/productData";

function ProductGrid() {
  return (
    <div className="mt-20 w-full xl:w-9/12 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-10 md:gap-x-10">
      {productGrid.map((product, index) => (
        <Reveal isStaggered={true} key={index} itemIndex={index}>
          <Link
            id="RouterNavLink"
            to={`/product/product-detail/${product.link}`}
          >
            <div className="rounded-lg py-5 lg:py-10 px-4 lg:px-8 border border-borderColor h-200 max-h-[400px] overflow-auto hover:border-primaryColor transition-all cursor-pointer">
              <div className="rounded-full w-[80px] h-[80px] bg-white overflow-hidden flex items-center justify-center">
                <img
                  src={product.logo}
                  alt={product.title + " logo"}
                  className="object-cover w-[50px]"
                />
              </div>
              <div className="mt-8 text-left">
                <h4 className="text-white text-3xl">{product.title}</h4>
                <p className="text-greyTone mt-2">{product.description}</p>
              </div>
            </div>
          </Link>
        </Reveal>
      ))}
    </div>
  );
}

export default ProductGrid;
