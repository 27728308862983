import logo1 from "./../media/power-automate-logo.png";
import logo2 from "./../media/zapier-logo.png";
import logo3 from "./../media/slack-icon.png";

const productGrid = [
  {
    title: "Power Automate",
    link: "power-automate",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget leo vestibulum, consequat lorem sed, facilisis dolor.",
    logo: logo1,
  },
  {
    title: "Zapier",
    link: "zapier",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget leo vestibulum, consequat lorem sed, facilisis dolor.",
    logo: logo2,
  },
  {
    title: "Slack",
    link: "slack",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget leo vestibulum, consequat lorem sed, facilisis dolor.",
    logo: logo3,
  },
];

export default productGrid;
