import React from "react";

function BorderSeparator() {
  return (
    <div className="py-8 lg:py-24 mx-auto">
      <div className="border border-borderColor/40"></div>
    </div>
  );
}

export default BorderSeparator;
