import WebAutomationAnimation from "./../media/web-automation.json";
import BuildPipelineTestingAnimation from "./../media/pipeline-testing.json";
import AutomatedTestingAnimation from "./../media/automated-testing.json";
import DataIntegrationAnimation from "./../media/data-integration.json";
import WorkflowOrchestrationAnimation from "./../media/workflow-orchestration.json";
import ComplianceAutomationAnimation from "./../media/compliance-automation.json";

const solutionsGrid = [
  {
    title: "Web Automation",
    description:
      "Effortlessly automate web processes for enhanced productivity. Simplify repetitive tasks with ease and optimization.",
    image: WebAutomationAnimation,
    link: "web-automation",
  },
  {
    title: "Pipeline Testing",
    description:
      "Streamline pipeline testing to ensure seamless development workflows. Optimize your development process.",
    image: BuildPipelineTestingAnimation,
    link: "pipeline-testing",
  },
  {
    title: "Automated Testing",
    description:
      "Automate testing processes to accelerate software deployment and ensure reliability. Ensure flawless software releases.",
    image: AutomatedTestingAnimation,
    link: "automated-testing",
  },
  {
    title: "Data Integration",
    description:
      "Seamlessly integrate data across platforms for enhanced decision-making. Unlock insights with unified data.",
    image: DataIntegrationAnimation,
    link: "data-integration",
  },
  {
    title: "Workflow Orchestration",
    description:
      "Optimize workflow orchestration for streamlined operations and improved efficiency. Coordinate tasks seamlessly.",
    image: WorkflowOrchestrationAnimation,
    link: "workflow-orchestration",
  },
  {
    title: "Compliance Automation",
    description:
      "Automate compliance processes to ensure adherence to regulations and standards. Stay compliant effortlessly.",
    image: ComplianceAutomationAnimation,
    link: "compliance-automation",
  },
];

export default solutionsGrid;
