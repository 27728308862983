import React from "react";
import Reveal from "../../Controls/Reveal";
import AboutUs from "./../../../media/aboutUs.png";

function AboutAutonomu() {
  return (
    <section className="text-left flex flex-col lg:flex-row gap-10 items-center">
      <div className="w-full lg:w-6/12">
        <Reveal>
          <h3 className="text-white text-2xl lg:text-5xl">
            What is{" "}
            <span className="text-primaryColor italic font-bold">
              Autonomu?
            </span>{" "}
          </h3>
        </Reveal>

        <div className="text-greyTone mt-6 flex flex-col gap-y-4 text-sm lg:text-lg">
          <Reveal>
            <p>
              At the core of our web automation tool lies a commitment to
              simplicity, echoing the philosophy that less is indeed more. We've
              crafted a platform that centers around a singular, structured
              approach, akin to the delicate art of origami, where every fold
              represents a step towards efficiency and clarity.
            </p>
          </Reveal>
          <Reveal>
            <p>
              Our tool seamlessly integrates intuitive outlining and mind
              mapping functionalities, providing you with the tools you need to
              effortlessly organize and manage your tasks.
            </p>
          </Reveal>
          <Reveal>
            <p>
              Gone are the days of convoluted features and disconnected data.
              With our tool, you won't find yourself juggling between multiple
              tools to get the job done. Instead, you and your team can
              concentrate on what truly matters, cutting through the chaos of
              modern digital workflows and enabling seamless collaboration.
            </p>
          </Reveal>
          <Reveal>
            <p>
              In today's fast-paced world, where agility and rapid
              decision-making are paramount, our web automation tool empowers
              teams to thrive. It's a beacon of clarity in a sea of complexity,
              providing you with the tools you need to navigate through the
              digital landscape with confidence and ease.
            </p>
          </Reveal>
        </div>
      </div>
      <div className="w-full lg:w-6/12">
        <div className="w-9/12 mx-auto">
          <Reveal>
            <img src={AboutUs} className="mx-auto" alt="aboutUs" />
          </Reveal>
        </div>
      </div>
    </section>
  );
}

export default AboutAutonomu;
